<template>
  <v-col cols="12">
    <template>
      <v-row justify="center">
        <v-dialog v-model="eliminar" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea eliminar esta persona?</v-card-title>
            <v-card-text>Esta acción no se puede revertir y será permanente.</v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="eliminar = false; delItem = '';">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="eliminar = false; deleteItemDB()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
        </v-dialog>
      </v-row>
    </template>
    <v-dialog v-model="dialog" persistent max-width="600px" :key="dialog">
      <v-card>
        <v-card-title>
          <span class="headline">{{ formTitle }}</span>
        </v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col justify="center" cols="12" sm="12" md="12">
                <div class="text-center d-flex flex-column align-center justify-center">
                  <v-avatar              
                  size="140"
                >
                  <v-img
                    :src="editedItem.imagen ? imagePreview : editedIndex == -1 ? `https://i.imgur.com/XHCJYkR.jpg`: `https://ui-avatars.com/api/?name=${editedItem.nombre+' '+editedItem.paterno + ' '+editedItem.materno }&size=64`"
                    :lazy-src="currentImage"
                    height="25vh"
                  ></v-img>
                </v-avatar>
                </div>
                <v-file-input
                  v-model="editedItem.imagen"
                  accept="image/png, image/jpeg, image/bmp"
                  prepend-icon="mdi-camera"
                  @change="selectImage"
                  @click:clear="clearImagePreview()"
                  label="Imagen [Opcional]"
                ></v-file-input>
              </v-col>
              <v-col cols="8">
                <v-autocomplete
                  v-model="editedItem.idPuesto"
                  :items="puestos"
                  label="Puesto"
                  item-text="nombre"
                  item-value="idPuesto"
                  :error="editedItem.errorEstatusPuesto"
                  :error-messages="editedItem.errorEstatusTextoPuesto"
                  :key="up"
                ></v-autocomplete>
              </v-col>
              <v-col cols="4" class="pb-0">
                <p class="mb-0">¿Colabora en mantenimiento?</p>
                <v-switch
                  v-model="editedItem.mantenimiento"
                  :label="editedItem.mantenimiento ? 'Si' : 'No'"
                  class="mt-0"
                  color="success"
                  hide-details
                ></v-switch>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="editedItem.idTurno"
                  :items="turnos"
                  label="Turno"
                  item-text="nombre"
                  item-value="idTurno"
                  :error="editedItem.errorEstatusTurno"
                  :error-messages="editedItem.errorEstatusTextoTurno"
                  :key="up"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.idDepartamento"
                  label="Departamento [Opcional]"
                  :items="departamentos"
                  :item-text="nombreDepto"
                  item-value="idDepartamento"
                  clearable
                  @change="onChangeDepartamento(editedItem)"
                ></v-autocomplete>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.idsSubdepartamentos"
                  label="Subdepartamentos [Opcional]"
                  :items="subdepartamentosFiltrados"
                  :item-text="nombreDepto"
                  item-value="idSubdepartamento"
                  clearable
                  multiple
                  chips
                  deletable-chips
                ></v-autocomplete>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.codigo" label="Código [Opcional]" maxlength="15"></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.nombre" label="Nombre"
                :error="editedItem.errorEstatusNombre"
                :error-messages="editedItem.errorEstatusTextoNombre"
                :key="up"
                ></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.paterno" label="Apellido Paterno" 
                :error="editedItem.errorEstatusApellidoPaterno"
                :error-messages="editedItem.errorEstatusTextoApellidoPaterno"
                :key="up"
                ></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.materno" label="Apellido Materno [Opcional]"
                :error="editedItem.errorEstatusApellidoMaterno"
                :error-messages="editedItem.errorEstatusTextoApellidoMaterno"
                :key="up"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.email"
                  label="Email [Opcional]"
                  clearable
                  :error="editedItem.errorEstatusEmail"
                  :error-messages="editedItem.errorEstatusTextoEmail"
                  :key="up"
                ></v-text-field>
              </v-col>
              <v-col justify="center" cols="6" sm="6" md="6">
                <v-text-field v-model="editedItem.rfc" label="RFC [Opcional]" maxlength="20"
                :error="editedItem.errorEstatusRFC"
                :error-messages="editedItem.errorEstatusTextoRFC"
                :key="up"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.telefono1"
                  label="Teléfono 1 [Opcional]"
                  :counter="editedItem.telefono1!=null"
                  maxlength="10"
                  clearable
                  :error="editedItem.errorEstatusTel1"
                  :error-messages="editedItem.errorEstatusTextoTel1"
                  :key="up"
                ></v-text-field>
              </v-col>
              <v-col cols="6">
                <v-text-field
                  v-model="editedItem.telefono2"
                  label="Teléfono 2 [Opcional]"
                  :counter="editedItem.telefono2!=null"
                  maxlength="10"
                  clearable
                  :error="editedItem.errorEstatusTel2"
                  :error-messages="editedItem.errorEstatusTextoTel2"
                  :key="up"
                ></v-text-field>
              </v-col>
              <v-col v-if="editedIndex < 0" justify="center" cols="6" sm="6" md="6" class="mb-4">
                <datePicker
                  v-model="editedItem.incorporacion"
                  label="Fecha de Incorporación [Opcional]"
                  format="YYYY-MM-DD"
                  clearable
                  maxToday
                ></datePicker>
                <!-- <v-text-field
                v-else
                  v-model="editedItem.incorporacion"
                  label="Fecha de Incorporación [Opcional]"
                  readonly
                ></v-text-field> -->
                
                
              </v-col>
              <!-- <v-col justify="center" cols="6" sm="6" md="6" class="mb-4" v-if="editedItem.idPersona > 0">
                <datePicker
                  v-model="editedItem.fechaBaja"
                  label="Fecha de Baja [Opcional]"
                  format="YYYY-MM-DD"
                  clearable
                  maxToday
                ></datePicker>
              </v-col> -->
              <v-col justify="center" cols="6" :sm="editedIndex < 0 ? 6 : 12" :md="editedIndex < 0 ? 6 : 12" v-if="permisoVista('puestos','$')">
                <v-text-field type="number" min="0" v-model="editedItem.costoHora" label="Costo por Hora del Puesto" hide-details></v-text-field>
              </v-col>
              <v-col cols="12">
                <v-autocomplete
                  v-model="editedItem.areas"
                  label="Áreas [Opcional]"
                  :items="areas"
                  item-text="nombre"
                  item-value="idArea"
                  hide-details
                  multiple
                  chips
                  deletable-chips
                  clearable
                ></v-autocomplete>
              </v-col>
              <v-col justify="center" cols="12" sm="12" md="12">
                <v-autocomplete
                  v-model="editedItem.habilidades"
                  :items="habilidades"
                  label="Habilidades [Opcional]"
                  item-text="nombre"
                  chips
                  multiple
                  item-value="idHabilidad"
                ></v-autocomplete>
              </v-col>
            </v-row>
            <ul>
              <li class="red--text" v-for="ex in errores" :key="ex">{{ ex }}</li>
            </ul>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="dialog=false; errores=[];">Cancelar</v-btn>
          <v-btn color="red darken-1" text @click="save()" :disabled="saving" :loading="saving">
            Guardar
            <template v-slot:loader>
              <v-progress-circular
                indeterminate
                :width="2"
                :size="24"
                color="red"
              ></v-progress-circular>
            </template>
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
			v-model="cargaMasiva"
			persistent
			max-width="40%"
		>
			<v-card>
				<v-card-title>
					Carga Masiva de Personas 
				</v-card-title>
				<template v-if="estadoCarga == 0">
					<v-card-text>
						<v-file-input
      			  v-model="ficheroActualizacion"
      			  label="Formato de Carga Masiva de Personas (.csv)"
      			  accept=".csv"
      			  counter
      			  show-size
      			  @change="cambioFicheroActualizacion"
      			></v-file-input>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
						<v-btn @click="cerrarCargaMasiva" text color="green">Cancelar</v-btn>
						<v-btn @click="realizarCargaMasiva" text color="red">Guardar</v-btn>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 1">
					<v-card-text class="text-center">
						<v-progress-circular
							indeterminate
							size="80"
							width="6"
							color="primary"
						></v-progress-circular>
						<p class="text-h6">Cargando</p>
					</v-card-text>
				</template>
				<template v-if="estadoCarga == 2">
					<v-card-text class="text-center py-0">
						<v-col cols="12" md="12" sm="12">
              <template v-if="erroresCargaMasiva.length == 0">
                <v-icon
                  size="100"
                  color="success"
                >
                  mdi-check-circle
                </v-icon>
                <p class="text-h6">Carga Realizada Correctamente</p>
              </template>
              <template v-else>
                <p class="text-h6">Han ocurrido errores al realizar la carga masiva: </p>
                <p v-for="(item, index) in erroresCargaMasiva" :key="index">
                  <v-icon color="red">mdi-alert-circle-outline</v-icon> {{item}}
                </p>
              </template>
            </v-col>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>
				<template v-if="estadoCarga == 3">
					<v-card-text class="text-center">
						<v-icon
							size="100"
							color="red"
						>
							mdi-close-circle-outline
						</v-icon>
						<div class="text-h6">Ha sucedido un Error. Intente nuevamente.</div>
					</v-card-text>
					<v-card-actions>
						<v-spacer></v-spacer>
							<v-btn text color="green" @click="cerrarCargaMasiva">Aceptar</v-btn>
						<v-spacer></v-spacer>
					</v-card-actions>
				</template>

			</v-card>
		</v-dialog>
    <!--<v-dialog v-model="calculoBonosMasiva" persistent width="700">-->
    <v-dialog v-model="calcularBonosPeriodo" persistent width="900">
      <v-card>
        <v-card-title>Cálculo de bonos</v-card-title>
        <v-card-text>
          <v-col cols="12">
          <v-row class="d-flex justify-center">
            <datePicker
              v-model="fechaInicioBono"
              format="YYYY-MM-DD"
              :maxDate="fechaFinBono != null ? fechaFinBono : maxDate"
              label="Calcular desde [Opcional]"
              clearable
              maxToday
              :error="errorFecha"
              :error-messages="errorFechaTexto"
              class="mr-5"
            ></datePicker>
            <datePicker
              v-model="fechaFinBono"
              format="YYYY-MM-DD"
              :minDate="fechaInicioBono"
              :maxDate="maxDate"
              label="Hasta [Opcional]"
              clearable
              maxToday
              :error="errorFecha"
              :error-messages="errorFechaTexto"
            ></datePicker>
          </v-row>
          </v-col>
          <v-data-table
            :headers="headersCalculoBonos"
            :items="calculoBonos"
             :loading="loadingCalculoBonos"
             class="mt-5"
          >
            <template v-slot:[`item.persona`]="{ item }">
              {{ nombrePersona(item.idPersona) }}
            </template>
            <template v-slot:[`item.montoObtenido`]="{ item }">
              $ {{ item.montoObtenido }}
            </template>
            <template v-slot:[`item.porcentajeAlcanzado`]="{ item }">
              {{ item.porcentajeAlcanzado }} %
            </template>
          <template v-slot:[`item.conceptoResultados`]="{ item }">
      <v-list dense>
        <v-list-item-group>
          <v-list-item
            v-for="(concepto, index) in item.conceptoResultados"
            :key="index"
          >
            <v-list-item-content>
              <v-list-item-title v-if="concepto.concepto">{{ concepto.concepto }} ({{ concepto.porcentajeEsperado }}%)</v-list-item-title>
              <v-list-item-subtitle>
                Porcentaje: {{ concepto.porcentajeConcepto }}%
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-list-item-group>
      </v-list>
    </template>
          </v-data-table>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" class="ml-auto" @click="hide_calculoBonos();" :loading="loadingCalculoBonos">
            Cancelar
          </v-btn>
          <v-btn text color="error" @click="getCalculoBonos()" :loading="loadingCalculoBonos">
            Calcular
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="despido" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea despedir a esta <br> persona?</v-card-title>
            <v-card-text>
                <v-row>
                  <v-col cols="12" md="12" sm="12">
                      <datePicker
                        v-model="fechaDespido"
                        format="YYYY-MM-DD"
                        :maxDate="maxDate"
                        :minDate="new Date(editedItem.ultimaFechaIncorporacion)"
                        label="Fecha de baja (Obligatorio)"
                        clearable
                        maxToday
                        :error="errorFecha"
                        :error-messages="errorFechaTexto"
                      ></datePicker>
                  </v-col>
                  <v-col cols="12" md="12" sm="12">
                    <v-textarea
                      v-model="motivo"
                      label="Motivo (Opcional)"
                      
                      auto-grow
                      rows="1"
                    ></v-textarea>
                  </v-col>
                </v-row>
                </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancelarDespido()">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="despedirPersona()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
    <v-dialog v-model="recontrantarDialog" persistent max-width="500px" :key="eliminar">
          <v-card>
            <v-card-title class="headline">¿Está seguro que desea recontratar a esta <br> persona?</v-card-title>
            <v-card-text>
                
                </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="cancelarDespido()">Cancelar</v-btn>
              <v-btn color="red darken-1" text @click="RecontrararPersona()">Aceptar</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
    <v-dialog v-model="dialogViewHistory" persistent max-width="75%" :key="eliminar">
          <v-card>
            <v-card-title class="headline">Historial</v-card-title>
            <v-card-text><v-btn text color="primary" @click="show_dialogAntiguedad()">Nueva Antigüedad</v-btn></v-card-text>
            <v-card-text>
                <v-simple-table>
                  <template v-slot:default>
                    <thead>
                      <tr>
                        <th class="text-center">
                          Fecha Incorporación
                        </th>
                        <th class="text-center">
                          Fecha Baja
                        </th>
                        <th class="text-center">
                          Motivo baja
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      <tr v-if="historial.length == 0">
                        <td style="text-align: center;" colspan="3">Sin datos</td>
                      </tr>
                      <tr
                      v-else
                        v-for="(historia, x) in historial" :key="x"
                      >
                        <td style="text-align: center;">{{ historia.inicio }}</td>
                        <td v-if="historia.fin == null" style="text-align: center;" >N/A</td>
                        <td v-else style="text-align: center;">{{ historia.fin }}</td>
                        <td v-if="historia.descripcion == null" style="text-align: center;" >N/D</td>
                        <td v-else style="text-align: center;"> {{ historia.descripcion }}</td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
            </v-card-text>
            <v-card-actions>
              <v-spacer></v-spacer>
              <v-btn color="blue darken-1" text @click="closeViewHistory()">Cerrar</v-btn>
            </v-card-actions>
          </v-card>
    </v-dialog>
    <v-row class="justify-space-between align-center">
      <v-col cols="4">
        <v-select
          v-model="filtros.fechaBaja"
          label="Estado"
          :items="estadosEmpleado"
          hide-details
          clearable
          @change="getPersonas()"
        ></v-select>
      </v-col>
      <v-col cols="6">
        <v-text-field
            v-model="search"
            append-icon="search"
            label="Buscar"
            single-line
            hide-details
          ></v-text-field>
      </v-col>
    </v-row>
    <v-col cols="12" class="d-flex" v-if="!calculoBonosMasiva">
      <v-btn color="primary" dark class="mb-2 mr-auto" @click="dialog=true" v-if="permisoVista('personas','c')">Nueva Persona</v-btn>
      <download-btn
        color="primary"
        text
        @click="descargarFormatoCargaMasiva"
        label="Descargar Formato<br>de Carga Masiva"
      />
      <v-btn 
        color="primary" 
        text 
        @click="cargaMasiva = true"
      >
        Carga Masiva de<br>Personas
      </v-btn>
      <v-btn color="primary" text @click="calculoBonosMasiva = true">
        Calcular bonos
      </v-btn>
    </v-col>
    <v-col cols="12" class="d-flex" v-else>
      <v-btn color="primary" @click="hide_calculoBonos()">
        Cancelar Calcular bonos
      </v-btn>
      <v-btn color="primary" :disabled="usuariosBonos.length > 0 ? false : true" @click="calcularBonosPeriodo = true">
        Calcular Bonos de <span><br></span> {{ usuariosBonos.length }} Usuarios
      </v-btn>
    </v-col>
    <v-data-table
      v-model="usuariosBonos"
      :single-select="false"
      item-key="idPersona"
      item-value="idPersona"
      :show-select="calculoBonosMasiva"
      v-if="permisoVista('personas','r')"
      :headers="headersFiltrados"
      :items="personas"
      :search="search"
      :loading="loading"
      loading-text="Cargando... Espere, Por Favor."
    >
      <template v-slot:[`item.codigo`]="{ item }">
        {{item.codigo != null ? item.codigo : 'N/A' }}
      </template>
      <template v-slot:[`item.estado`]="{ item }">
        {{item.estado ? "Activo" : 'Inactivo' }}
      </template>
      <template v-slot:[`item.costoHora`]="{ item }">
        ${{item.costoHora.toFixed(2)}}
      </template>
      <!-- // -->
      <template v-slot:[`item.rfc`]="{ item }">
        {{item.rfc != null ? item.rfc : 'N/A' }}
      </template>
      <template v-slot:[`item.action`]="{ item }">

        <v-tooltip v-if="item.estado" bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('personas','u')"
          small
          class="mr-2"
          @click="dialogDespido(item)"
        >
          mdi-account-arrow-right
        </v-icon>
         </template>
          <span class="white--text">Despedir</span>
        </v-tooltip>

        <v-tooltip v-else bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('personas','u')"
          small
          class="mr-2"
          @click="recontratarShow(item)"
        >
          mdi-account-arrow-down
        </v-icon>
         </template>
          <span class="white--text">Recontratar</span>
        </v-tooltip>

        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('personas','u')"
          small
          class="mr-2"
          @click="viewHistory(item)"
        >
          mdi-clipboard-text-clock
        </v-icon>
         </template>
          <span class="white--text">Historial</span>
        </v-tooltip>

        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('personas','u')"
          small
          class="mr-2"
          @click="editItem(item)"
        >
          edit
        </v-icon>
         </template>
          <span class="white--text">Editar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          v-if="permisoVista('personas','d')"
          small
          class="mr-2"
          @click="deleteItem(item)"
        >
          delete
        </v-icon>
         </template>
          <span class="white--text">Eliminar</span>
        </v-tooltip>
        <v-tooltip bottom color="black" class="white--text" v-if="!calculoBonosMasiva">
          <template v-slot:activator="{ on }">
        <v-icon v-on="on"
          small
          class="mr-2"
          @click="asignacionBonos(item)"
        >
          mdi-cash-plus
          mdi-account-cash
        </v-icon>
         </template>
          <span class="white--text">Agregar Bono</span>
        </v-tooltip>
      </template>
      <template v-slot:no-data>
        <v-btn color="primary" @click="initialize">Refrescar</v-btn>
      </template>
    </v-data-table>
    <v-snackbar
      v-model="alerta"
      top
      color="error"
    >
      Existe una Persona con el Mismo RFC
      <v-btn
        text
        @click="alerta = false"
      >
        Cerrar
      </v-btn>
    </v-snackbar>

    <v-dialog v-model="dialogAntiguedad" persistent max-width="600px">
      <v-card>
        <v-card-title>Nueva Antigüedad</v-card-title>
        <v-card-text>
          <v-container>
            <v-row>
              <v-col cols="6">
                <VueCtkDateTimePicker v-model="fechaInicioAntiguedad" :max-date="maxFecha" @input="errorFechaInicioAntiguedad = ''" onlyDate
                  format="YYYY-MM-DD" formatted="YYYY-MM-DD" label="Fecha de Incorporación" noButtonNow noHeader overlay
                  :error="errorFechaInicioAntiguedad != ''"/>
                  <p v-if="errorFechaInicioAntiguedad" class="red--text pt-1 mb-0" style="font-size: 12px !important">
                  {{ errorFechaInicioAntiguedad }}</p>
              </v-col>
              <v-col cols="6">
                <VueCtkDateTimePicker v-model="fechaFinAntiguedad" :min-date="fechaInicioAntiguedad" :max-date="obtenerFechaActual()"
                 @input="errorFechaFinAntiguedad = ''" onlyDate format="YYYY-MM-DD" formatted="YYYY-MM-DD" label="Fecha de Baja" noButtonNow
                 noHeader overlay :error="errorFechaFinAntiguedad != ''"/>
                 <p v-if="errorFechaFinAntiguedad" class="red--text pt-1 mb-0" style="font-size: 12px !important">
                  {{ errorFechaFinAntiguedad }}</p>
              </v-col>
              <v-col cols="12">
                <v-textarea v-model="motivoAntiguedad" label="Motivo de Baja (Opcional)" rows="1" auto-grow/>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>
        <v-card-actions>
          <v-btn text color="primary" class="ml-auto" @click="hide_dialogAntiguedad" :loading="loadingNAntiguedad">Cerrar</v-btn>
          <v-btn text color="error" @click="generarAntiguedad()" :loading="loadingNAntiguedad">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="dialogBonos" persistent :width="mostrarDatosBono ? '100%' : '700px'">
      <v-card>
        <v-card-title>Asignación de bonos para: {{ infoUsuario.nombre }}</v-card-title>
        <v-card-text>
          <v-col cols="12">
             <v-checkbox v-model="mostrarDatosBono" label="Mostrar todos los datos" />
          </v-col>
          <center v-if="loadingMostrarBonos">
          <v-progress-circular class="mx-auto" :size="50" color="primary" indeterminate ></v-progress-circular>
          </center>
          <v-simple-table v-else>
            <thead>
              <tr> 
                <th class="text-center" v-text="'Código'" v-if="mostrarDatosBono"/>
                <th class="text-center" v-text="'Nombre'" />
                <th class="text-center" v-if="mostrarDatosBono" dense max-width="200">
                  Requisitos
                </th>
                <th class="text-center" v-if="mostrarDatosBono">
                  Conceptos
                </th>
                <th class="text-center">
                  Monto
                </th>
                <th class="text-center">
                  Estado
                </th>
                <th class="text-center">
                  Acción
                </th>
              </tr>
            </thead>
            <tr v-for="item in bonosUsuario" :key="item.idBono">
              <td class="text-center" v-if="mostrarDatosBono">{{ item.codigo }} </td>
              <td class="text-center">{{ item.nombre }} </td>
              <td v-if="mostrarDatosBono" dense width="200px">
                <v-list-item class="text-center" style="margin-top: -7px; margin-bottom: -7px" dense>
                  <v-list-item-content>
                    <v-list-item-title >{{ 'Área' }}: {{ item.nombreArea }}</v-list-item-title>
                    <v-list-item-title>{{ 'Puesto' }}: {{ item.nombreCargo }}</v-list-item-title>
                    <v-list-item-title>{{ 'Turno' }}: {{ item.nombreTurno }}</v-list-item-title>
                    <v-list-item-title>{{ 'Máquina' }}: {{ item.nombreMaquina }}</v-list-item-title>
                    <v-list-item-title>{{ 'No. Habilidades' }}: {{ item.numHabilidades }}</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td class="text-center" v-if="mostrarDatosBono">
                <v-list-item style="margin-top: -7px; margin-bottom: -7px" dense v-for="concepto,idx in item.bonoConceptos" :key="`concepto-${idx}`">
                  <v-list-item-content :key="concepto.idConcepto">
                    <v-list-item-title>{{concepto.descripcion}}: {{concepto.porcentaje}}%</v-list-item-title>
                  </v-list-item-content>
                </v-list-item>
              </td>
              <td class="text-center">$ {{ item.total }} </td>
              <td class="py-2 white--text" v-if="dialogBonos && item && cumpleParaBono(item).length >= 1" style="background-color: red;">
                <center>
                  <v-list-item class="white--text" v-for="error,idx in cumpleParaBono(item)" dense :key="`error-${idx}`">
                    <v-list-item-content class="white--text" dense>
                      <v-list-item-title class="white--text" dense>{{error}}</v-list-item-title>
                    </v-list-item-content>
                    <br>
                  </v-list-item>
                </center>
              </td>
              <td class="py-2 white--text" v-else style="background-color: green;">
                <center>
                  Cumple con los requisitos
                </center>
              </td>
              <td>
                <v-col cols="12">
                  <v-row align="center">
                    <v-checkbox class="ml-auto mr-auto" label="Asignar" v-model="item.status" />
                  </v-row>
                </v-col>
              </td>
            </tr>
          </v-simple-table>
        </v-card-text>
        <v-card-actions>
          <v-btn @click="hide_asignacionBonos()" class="ml-auto" color="primary" text :loading="loadingBonosUsuario">Cancelar</v-btn>
          <v-btn color="error" @click="postBonosUsuario()" text :loading="loadingBonosUsuario">Guardar</v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-col>
  
</template>

<script>
import axios from "axios";
import datePicker from "@/components/DatePicker";
import { VueDatePicker } from "@mathieustan/vue-datepicker";
import DownloadBtn from "../../DownloadBtn.vue";
import moment from 'moment';
import { mapState } from 'vuex';
import Historial from '@/components/DashViews/Mantenimiento/Historial.vue';

  export default {
    components: {
      datePicker: datePicker,
      DownloadBtn
    },
    data: () => ({
      maxDate: new Date(),
      dialogViewHistory: false,
      recontrantarDialog: false,
      despido: false,
      fechaDespido: null,
      motivo: null,
      errorFecha: false,
      errorFechaTexto: null,
      up1: 1,
      departamentos: [],
      subdepartamentos: [],
      errorTelefono1: "",
      errorTelefono2: "",
      rules: {
        telefono: (value) => {
          const pattern = /^\d{10}$/
          return pattern.test(value);
        },
      },
      estadosEmpleado: [
        {
          text: "Activos",
          value: false
        },
        {
          text: "Inactivos",
          value: true
        }
      ],
      filtros: {
        fechaBaja: null,
      },
      areas: [],
      erroresCargaMasiva: [],
      estadoCarga: 0,
      ficheroActualizacion: null,
      cargaMasiva: false,
      saving: false,
      puestos: [],
      loadingPuesto: false,
      habilidades:[],
      loadingHabilidad: false,
      dialog: false,
      alerta: false,
      eliminar: false,
      historial:[],
      search: '',
      headers: [
        {
          text: 'Estado',
          align: 'left',
          sortable: true,
          value: 'estado',
        },
        {
          text: 'Código',
          align: 'left',
          sortable: true,
          value: 'codigo',
        },
        {
          text: 'Nombre',
          align: 'left',
          sortable: true,
          value: 'nombre',
        },
        {
          text: 'Paterno',
          align: 'left',
          sortable: true,
          value: 'paterno',
        },
        {
          text: 'Materno',
          align: 'left',
          sortable: true,
          value: 'materno',
        },
        {
          text: 'Puesto',
          align: 'left',
          sortable: true,
          value: 'puesto',
        },
        {
          text: 'Turno',
          align: 'left',
          sortable: true,
          value: 'turno',
        },
        {
          text: 'RFC',
          align: 'left',
          sortable: true,
          value: 'rfc',
        },
        {
          text: 'Costo por Hora',
          align: 'center',
          sortable: true,
          value: 'costoHora',
        },
        { text: 'Acciones', value: 'action', sortable: false, width:'20%', align:'center' },
      ],
      loading: false,
      personas: [],
      delItem: '',
      action: 0,
      errores: [],
      editedIndex: -1,
      editedItem: {
        nombre: null,
        paterno:null,
        materno: null,
        rfc: null,
        idDepartamento: null,
        idsSubdepartamentos: null,
        codigo: null,
        idPuesto:-1,
        idTurno:-1,
        habilidades: [],
        costoHora:"0.00",
        telefono1: null,
        telefono2: null,
        incorporacion: null,
        /* fechaBaja: null, */
        areas: [],
        mantenimiento: false,
        email: null,
        imagen: null,
        errorEstatusPuesto: false,
        errorEstatusTextoPuesto: null,
        errorEstatusNombre: false,
        errorEstatusTextoNombre: null,
        errorEstatusApellidoPaterno: false,
        errorEstatusTextoApellidoPaterno: null,
        errorEstatusApellidoMaterno: false,
        errorEstatusTextoApellidoMaterno: null,
        errorEstatusRFC: false,
        errorEstatusTextoRFC: null,
        errorEstatusEmail: false,
        errorEstatusTextoEmail: null,
        errorEstatusTel1: false,
        errorEstatusTextoTel1: null,
        errorEstatusTel2: false,
        errorEstatusTextoTel2: null,
        errorEstatusTurno: false,
        errorEstatusTextoTurno: null,
        ciclo:null,
        ultimaFechaIncorporacion: null
      },
      defaultItem: {
        nombre: null,
        paterno:null,
        materno: null,
        rfc: null,
        codigo: null,
        idDepartamento: null,
        idsSubdepartamentos: null,
        idPuesto:-1,
        idTurno:-1,
        habilidades: [],
        costoHora: "0.00",
        telefono1: null,
        telefono2: null,
        incorporacion: null,
        /* fechaBaja: null, */
        areas: [],
        mantenimiento: false,
        email: null,
        imagen: null,
        errorEstatusPuesto: false,
        errorEstatusTextoPuesto: null,
        errorEstatusNombre: false,
        errorEstatusTextoNombre: null,
        errorEstatusApellidoPaterno: false,
        errorEstatusTextoApellidoPaterno: null,
        errorEstatusApellidoMaterno: false,
        errorEstatusTextoApellidoMaterno: null,
        errorEstatusRFC: false,
        errorEstatusTextoRFC: null,
        errorEstatusEmail: false,
        errorEstatusTextoEmail: null,
        errorEstatusTel1: false,
        errorEstatusTextoTel1: null,
        errorEstatusTel2: false,
        errorEstatusTextoTel2: null,
        errorEstatusTurno: false,
        errorEstatusTextoTurno: null,
        ciclo:null,
        ultimaFechaIncorporacion: null
      },
      loadingTurno:false,
      turnos:[],
      image: "",
      imagePreview: null,
      up: 0,

      dialogAntiguedad: false,
      fechaInicioAntiguedad: '',
      fechaFinAntiguedad: '',
      maxFechaAntiguedad: '',
      errorFechaInicioAntiguedad: '',
      errorFechaFinAntiguedad: '',
      motivoAntiguedad: '',
      idPersonaAntiguedad: '',
      loadingNAntiguedad: false,

      usuariosBonos: [],
      calcularBonosPeriodo: false,
      calculoBonosMasiva: false,
      loadingBonosUsuario: false,
      dialogBonos: false,
      headersCalculoBonos: [{ 
        text: 'Persona', 
        value: 'persona',
        align: "center",
        sortable: false
       }, { 
        text: 'Periodo',
        value: 'periodoUsado',
        align: "center",
        sortable: false
       },{ 
        text: 'Conceptos y Porcentajes',
        value: 'conceptoResultados',
        align: "center",
        sortable: false
       }, { 
        text: 'Monto Obtenido', 
        value: 'montoObtenido',
        align: "center",
        sortable: false
       }, { 
        text: '% Alcanzado', 
        value: 'porcentajeAlcanzado',
        align: "center",
        sortable: false
       }
      ],
      calculoBonos: [],
      bonos: [],
      bonosUsuario: [],
      infoUsuario: {},

      fechaInicioBono: null,
      fechaFinBono: null,
      mostrarDatosBono: false,
      loadingCalculoBonos: false,
      loadingMostrarBonos: false,
    }),

    computed: {
      ...mapState(["token"]),
      headersFiltrados(){
        return this.permisoVista('personas','$')
          ? this.headers
          : this.headers.filter(head=>head.value!='costoHora')
      },
      formTitle () {
        return this.editedIndex === -1 ? 'Nueva Persona' : 'Editar Persona'
      },
      subdepartamentosFiltrados(){
        return this.editedItem.idDepartamento == null
          ? []
          : this.subdepartamentos.filter( subdepto => subdepto.departamento.idDepartamento == this.editedItem.idDepartamento );
      },
      maxFecha() {
        return this.fechaFinAntiguedad ? this.fechaFinAntiguedad : this.maxFechaAntiguedad
      }
    },

    watch: {
      dialog (val) {
        val || this.close()
      },/*
      fechaInicioBono() {
        this.getCalculoBonos();
      },
      fechaFinBono() {
        this.getCalculoBonos();
      },*/
    },

    created () {
      this.initialize()
    },

    methods: {
      nombreDepto: ({ codigo, nombre }) => codigo ? `[${codigo}] - ${nombre}`.trim() : `${nombre}`.trim(),
      onChangeDepartamento(editedItem){
        this.$set(editedItem,'idsSubdepartamentos',null);
      },
      realizarCargaMasiva(){
        if (this.ficheroActualizacion == null) return;
        this.estadoCarga = 1;
        let formData = new FormData();
        formData.append("formfile", this.ficheroActualizacion);
        axios
          .post("/Personas/CargaMasivaOperadores", formData, {
            headers: {
              "Content-Type": "multipart/form-data"
            }
          })
          .then((response) => {
            this.erroresCargaMasiva = response.data;
            this.estadoCarga = 2;
            this.initialize();
          })
          .catch(error => {
            this.estadoCarga = 3;
            console.log(error);
          });
      },
      cerrarCargaMasiva(){
        this.cargaMasiva = false;
        this.estadoCarga = 0;
        this.ficheroActualizacion = null;
        this.erroresCargaMasiva = [];
      },
      cambioFicheroActualizacion(event) {
        if (typeof event === "undefined" || event == null)
          this.ficheroActualizacion = null;
        else this.ficheroActualizacion = event;
      },
      descargarFormatoCargaMasiva(){
        var datetime = moment().format('YYYYMMDDHHmmss');
        this.$utils.axios.downloadFile('/Personas/PlantillaCargaMasivaOperadores?access_token='+this.token,'GET','formato-cargamasiva-operadores-'+datetime+'.csv','text/csv');
      },
      validFloat(value){
        return isNaN(parseFloat(value))?0.0:parseFloat(value);
      },
      initialize () {
        this.getPuestos();
        this.getHabiliades();
        this.getTurnos();
        this.getAreas();
        this.getPersonas();
        this.getDepartamentos();
        this.getSubdepartamentos();
        this.getBonos();
        if (!this.fechaFinAntiguedad) {
          this.maxFechaAntiguedad = this.obtenerFechaActual();
        }
      },
      getDepartamentos(){
        axios
          .get('/Departamento')
          .then( response => {
            this.departamentos = response.data;
          })
          .catch(console.log);
      },
      getSubdepartamentos(){
        axios
          .get('/Subdepartamento')
          .then( response => {
            this.subdepartamentos = response.data;
          })
          .catch(console.log);
      },
      getPersonas(){
        this.loading = true;
        axios
        .get("/Personas",{
          params: this.filtros
        })
        .then(response =>  {
          this.personas = response.data.map( persona => ({
            ...persona,
            telefono1: persona.telefono ? persona.telefono.split(',')[0] ? persona.telefono.split(',')[0] : null : null,
            telefono2: persona.telefono ? persona.telefono.split(',')[1] ? persona.telefono.split(',')[1] : null : null
          }));
          this.loading = false;
        })
        .catch(error => {
          this.loading = false;
          console.log(error);
        });
      },
      getAreas(){
        axios
          .get('/Areas')
          .then( response => {
            this.areas = response.data.filter( area => area.idArea > 1 );
          })
          .catch( error => {
            console.log(error);
          })
      },
      getTurnos(){
        let me = this;
        me.loadingTurno = true;
        axios
        .get("/Turnos")
        .then(response =>  {
          me.turnos = response.data;
          me.loadingTurno = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getPuestos(){
        let me = this;
        me.loadingPuesto = true;
        axios
        .get("/Puestos")
        .then(response =>  {
          me.puestos = response.data;
          me.loadingPuesto = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getHabiliades(){
        let me = this;
        axios
        .get("/Habilidades")
        .then(response =>  {
          me.habilidades = response.data;
          me.loadingHabilidad = false;
        })
        .catch(error => {
          console.log(error);
        });
      },
      getBonos(){
        axios
        .get("/Bonos/listar")
        .then(response => {
          this.bonos = response.data;
        })
        .catch(error => {
          console.log(error)
        })
      },
      postBonosUsuario() {
        let bonosAplicados = this.bonosUsuario.filter(item => item.status).map(item => item.idBono);
        this.loadingBonosUsuario = true;
        axios
        .post(`OperadorBonos/ActualizarOperadorBonos/${this.infoUsuario.idPersona}`, 
          {
            bono: bonosAplicados
          }
        )
        .then(response => {
          this.hide_asignacionBonos()
        })
        .catch(error => {
          console.log(error)
        })
        .finally(() => {
          this.getBonos()
          this.loadingBonosUsuario = false;
        })
      },
      getCalculoBonos(){
        this.loadingCalculoBonos = true;
          let personasIds = this.usuariosBonos.map(item => item.idPersona);
          let params = {};
          personasIds.forEach((id, index) => {params[`personas[${index}]`] = id;});

          if (this.fechaInicioBono && this.fechaFinBono) {
            params.FechaInicio = this.fechaInicioBono;
            params.FechaFin = this.fechaFinBono;
          }
          axios
          .get('/CalculoBono/Calculos', {params: params})
          .then(response => {
            this.calculoBonos = response.data
          })
          .catch(error => {
            console.log(error)
          })
          .finally(()=>{
            this.loadingCalculoBonos = false;
          })
      },
      editItem (item) {
        this.action = 1;
        this.editedIndex = this.personas.indexOf(item)
        this.editedItem = Object.assign({}, item)
        var nombreimagen = item.imagen;
        if(nombreimagen != null) this.showImage(nombreimagen)
        this.dialog = true
      },

      deleteItem (item) {
        this.eliminar = true;
        this.delItem = item;
      },
      deleteItemDB(){
        let me = this;
        let item = this.delItem;
        this.delItem = '';
        axios
          .put("/Personas/Desactivar/" + item.idPersona)
          .then(response =>  {
            me.initialize();
          })
          .catch(error => {
            console.log(error);
          });
      },
      close () {
        this.action = 0,
        this.dialog = false
        setTimeout(() => {
          this.editedItem = Object.assign({}, this.defaultItem)
          this.editedIndex = -1
          this.editedItem.habilidades = [];
        }, 300)
        this.errorTelefono1 = "";
        this.errorTelefono2 = "";
      },
      makeValidation(string, min, max, msgError) {
      if (string == null) return msgError;
      else if (string.length < min || string.length > max) {
        return msgError;
      } else return null;
    },
      validate() {
      if (this.editedItem.idPuesto <= 0) {
        this.editedItem.errorEstatusPuesto = true;
        this.editedItem.errorEstatusTextoPuesto = "Selecciona un puesto";
      } else {
        this.editedItem.errorEstatusPuesto = false;
        this.editedItem.errorEstatusTextoPuesto = null;
      }

      if (this.editedItem.idTurno <= 0) {
        this.editedItem.errorEstatusTurno = true;
        this.editedItem.errorEstatusTextoTurno = "Selecciona un turno";
      } else {
        this.editedItem.errorEstatusTurno = false;
        this.editedItem.errorEstatusTextoTurno = null;
      }

      var val = null;
      val = this.makeValidation(this.editedItem.nombre, 2, 100, "El Nombre debe tener al menos 2 caracteres y menos de 100." );
      if (val != null) {
        this.editedItem.errorEstatusNombre = true;
        this.editedItem.errorEstatusTextoNombre = val;
      } else {
        this.editedItem.errorEstatusNombre = false;
        this.editedItem.errorEstatusTextoNombre = null;
      }

      val = this.makeValidation(this.editedItem.paterno, 2, 100, "El Apellido Paterno debe tener al menos 2 caracteres y menos de 100." );

      if (val != null) {
        this.editedItem.errorEstatusApellidoPaterno = true;
        this.editedItem.errorEstatusTextoApellidoPaterno = val;
      } else {
        this.editedItem.errorEstatusApellidoPaterno = false;
        this.editedItem.errorEstatusTextoApellidoPaterno = null;
      }

      
      if (this.editedItem.rfc != null && this.editedItem.rfc.trim().length > 0 && this.editedItem.rfc.length > 0 ) {
        val = this.makeValidation(this.editedItem.rfc,13, 20, "El RFC debe tener minimo 13 caracteres y menos de 20.");

        if (val != null) {
          this.editedItem.errorEstatusRFC = true;
          this.editedItem.errorEstatusTextoRFC = val;
        }else {
          this.editedItem.errorEstatusRFC = false;
          this.editedItem.errorEstatusTextoRFC = null;
        }
      }else {
          this.editedItem.errorEstatusRFC = false;
          this.editedItem.errorEstatusTextoRFC = null;
        }

      if (this.editedItem.email != null) {
        const pattern =
          /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        if (!pattern.test(this.editedItem.email)) {
          this.editedItem.errorEstatusEmail = true;
          this.editedItem.errorEstatusTextoEmail = `El email introducido es inválido`;
        }else{
        this.editedItem.errorEstatusEmail = false;
          this.editedItem.errorEstatusTextoEmail = null;
      }
      }else{
        this.editedItem.errorEstatusEmail = false;
          this.editedItem.errorEstatusTextoEmail = null;
      }

      if (this.editedItem.telefono1 != null) {
        if (!this.rules.telefono(this.editedItem.telefono1)) {
          this.editedItem.errorEstatusTel1 = true;
          this.editedItem.errorEstatusTextoTel1 = `Valida el número introducido`;
        }else {
        this.editedItem.errorEstatusTel1 = false;
        this.editedItem.errorEstatusTextoTel1 = null;
      }
      } else {
        this.editedItem.errorEstatusTel1 = false;
        this.editedItem.errorEstatusTextoTel1 = null;
      }

      if (this.editedItem.telefono2 != null) {
        if (!this.rules.telefono(this.editedItem.telefono2)) {
          this.editedItem.errorEstatusTel2 = true;
          this.editedItem.errorEstatusTextoTel2 = `Valida el número introducido`;
        }else {
        this.editedItem.errorEstatusTel2 = false;
        this.editedItem.errorEstatusTextoTel2 = null;
      }
      } else {
        this.editedItem.errorEstatusTel2 = false;
        this.editedItem.errorEstatusTextoTel2 = null;
      }

      this.up++;

      if (this.editedItem.errorEstatusPuesto == false
      && this.editedItem.errorEstatusPuesto == false
       && this.editedItem.errorEstatusNombre == false
       && this.editedItem.errorEstatusApellidoPaterno == false
       && this.editedItem.errorEstatusRFC == false
        && this.editedItem.errorEstatusEmail == false
        && this.editedItem.errorEstatusTel1 == false
        && this.editedItem.errorEstatusTel2 == false
        && this.editedItem.errorEstatusTurno == false)
        return false;
      else return true;
    },
      save () {
        this.action = 0;
        if(this.validate()) return;
        
          const telefonos = [ this.editedItem.telefono1,this.editedItem.telefono2 ];

          let me = this;
          me.saving = true;

              try {

                var formData = new FormData();
			        formData.append("IdPuesto", me.editedItem.idPuesto);
              formData.append("IdTurno", me.editedItem.idTurno);
	            formData.append("Nombre", me.editedItem.nombre);
	            formData.append("Paterno", me.editedItem.paterno);
              if(this.editedItem.materno != null) formData.append("Materno", me.editedItem.materno);
	            
              if(this.editedItem.idDepartamento != null)
                if(this.editedItem.idDepartamento > 0) formData.append("idDepartamento", this.editedItem.idDepartamento);
	            

              if(this.editedItem.idsSubdepartamentos != null)
                this.editedItem.idsSubdepartamentos.forEach(element => {
                  if(element > 0) formData.append("idsSubdepartamentos", element);
                });

                if((me.editedItem.codigo != null && me.editedItem.codigo.trim().length > 0 && me.editedItem.codigo.length > 0))
	                formData.append("Codigo", me.editedItem.codigo);

              if(me.editedItem.rfc != null && me.editedItem.rfc.trim().length > 0 && me.editedItem.rfc.length > 0)
	              formData.append("RFC", me.editedItem.rfc);
	            
              this.editedItem.habilidades.forEach(element => {
                if(element > 0) formData.append("Habilidades", element);  
              });

	            formData.append("CostoHora", me.validFloat(me.editedItem.costoHora));
              if(this.editedItem.incorporacion != null){
                formData.append("Incorporacion", moment(this.editedItem.incorporacion).format("YYYY-MM-DD"));
              }
	            /* formData.append("fechaBaja", moment(this.editedItem.fechaBaja).format("YYYY-MM-DD")); */
	            
              this.editedItem.areas.forEach(element => {
                if(element > 0) formData.append("idsAreas", element);  
              });

	            formData.append("telefono", telefonos.join(','));
	            formData.append("mantenimiento", this.editedItem.mantenimiento);

              if(this.editedItem.email != null) formData.append("email", this.editedItem.email);
                  
	            formData.append("imagen", this.editedItem.imagen);

              
              
                 if (this.editedIndex > -1)
          {
            
            axios
              .put("/Personas/Actualizar/"+me.personas[me.editedIndex].idPersona,
              formData,{
                  headers: {
				           		"Content-Type": "multipart/form-data",
				           	},
                })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("* Existe una Persona con el Mismo RFC o Código");
                  }
                }
              });
          }
          else
          {
            axios
              .post("/Personas/Crear", 
              formData,{
                  headers: {
				           		"Content-Type": "multipart/form-data",
				           	},
                })
              .then(response =>  {
                me.saving = false;
                me.close();
                me.initialize();
              })
              .catch(error => {
                me.saving = false;
                if (error.response) {
                  if(error.response.status == 409){
                    me.alerta = true;
                    me.errores.push("Existe una Persona con el Mismo RFC o Código");
                  }
                }
              });
          }
                
              } catch (error) {
                console.log(error)
              }
      },
      async selectImage(e) {
      const file = e;
      if (!file) return;
      const readData = (f) =>
        new Promise((resolve) => {
          const reader = new FileReader();
          reader.onloadend = () => resolve(reader.result);
          reader.readAsDataURL(f);
        });
      const data = await readData(file);
      this.imagePreview = data;
      },
      async clearImagePreview() {
        this.imagePreview = "";
      },
      showImage(item) {
      const url = `/Personas/ImagenPersona?imagen=m_${item}&access_token=${this.token}`;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
        .then(async (response) => {
          let blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          this.currentImage = await this.$utils.images.blobToBase64(blob);
          this.showImageOriginal(item);
        })
        .catch((error) => {
          console.log(error);
        });
    },
    showImageOriginal(item) {
      const url = `/Personas/ImagenPersona?imagen=${item}&access_token=${this.token}`;
      axios({
        url: url,
        method: "GET",
        responseType: "blob",
        data: null,
      })
        .then(async (response) => {
          let blob = new Blob([response.data], {
            type: "application/octet-stream",
          });
          this.imagePreview = await this.$utils.images.blobToBase64(
            blob
          );
        })
        .catch((error) => {
          console.log(error);
        });
    },
    dialogDespido(item){
      this.fechaDespido = null;
      this.despido = true;
      this.editedItem = Object.assign({}, item)
    },
    despedirPersona(){
      if(this.fechaDespido == null){
        this.errorFecha = true;
        this.errorFechaTexto = 'Seleccione una fecha'
        this.up1++;
        return;
      }

      this.up1++;
      this.errorFecha = false;
      this.errorFechaTexto = null;
  
     var obj = {
      idPersona:this.editedItem.idPersona,
      fecha: this.fechaDespido,
      motivo: this.motivo
     }
     axios
						.post("/Personas/Despedir",obj)
						.then(() => {
							this.saving = false;
							this.cancelarDespido()
							this.initialize();
              this.editedItem = Object.assign({}, this.defaultItem)
						})
						.catch((error) => {
							this.saving = false;
							if (error.response) {
								if (error.response.status == 409) {
									this.alerta = true;
								}
							}
						});
      this.despido = true;
    },
    recontratarShow(item){
      this.fechaDespido = null;
      this.recontrantarDialog = true;
      this.editedItem = Object.assign({}, item)
    },
    RecontrararPersona(){
      axios
        .put("/Personas/Recontratar/" + this.editedItem.idPersona)
        .then(() => {
          this.cancelarDespido();
          this.initialize();
        })
        .catch((error) => {
          Error.log(error);
        });
    },
    cancelarDespido(){
      this.fechaDespido = null;
      this.despido = false;
      this.recontrantarDialog = false;
      this.motivo = null;
      this.editedItem = Object.assign({}, this.defaultItem)
      this.errorFechaTexto = null;
      this.errorFecha = null;
    },
    viewHistory(item){
      this.historial = item.ciclo;
      this.idPersonaAntiguedad = item.idPersona;
      this.dialogViewHistory = true;
    },
    closeViewHistory(){
      this.historial = [];
      this.idPersonaAntiguedad = ''
      this.dialogViewHistory = false;
    },

    show_dialogAntiguedad(){
      this.dialogAntiguedad = true
    },
    hide_dialogAntiguedad(){
      this.fechaInicioAntiguedad = this.fechaFinAntiguedad = this.motivoAntiguedad = ''
      this.dialogAntiguedad = false
    },
    obtenerFechaActual(){
      const fechaActual = new Date();
      const año = fechaActual.getFullYear();
      let mes = fechaActual.getMonth() + 1;
      let dia = fechaActual.getDate();

      if (mes < 10) {
        mes = `0${mes}`;
      }
      if (dia < 10) {
        dia = `0${dia}`;
      }

      const fechaFormateada = `${año}-${mes}-${dia}`;
      return fechaFormateada;
    },
    generarAntiguedad() {
      if (this.fechaInicioAntiguedad && this.fechaFinAntiguedad) {
        this.loadingNAntiguedad = true
        axios
          .post("Personas/CicloPersona",{
            idPersona: this.idPersonaAntiguedad,
            fechaInicio: this.fechaInicioAntiguedad,
            fechaFin: this.fechaFinAntiguedad,
            motivo: this.motivoAntiguedad != '' ? this.motivoAntiguedad : null
          })
          .then((response) => {
            this.loadingNAntiguedad = false
            this.hide_dialogAntiguedad();
            this.closeViewHistory();
            this.getPersonas();
          })
          .catch(error => {
            console.log(error)
          })
      }
      else {
        if (!this.fechaInicioAntiguedad) this.errorFechaInicioAntiguedad = "Debe seleccionar una Fecha"
        if (!this.fechaFinAntiguedad) this.errorFechaFinAntiguedad = "Debe seleccionar una Fecha"
      }
    },
    asignacionBonos(item) {
        this.bonosUsuario = JSON.parse(JSON.stringify([]))
        this.dialogBonos = true;
        this.loadingMostrarBonos = true;
        if (item) this.infoUsuario = JSON.parse(JSON.stringify(item));

        axios.get(`/OperadorBonos/ListarOperadorBonos/${this.infoUsuario.idPersona}`)
            .then(response => {
                const bonosOperador = response.data;
                this.bonosUsuario = JSON.parse(JSON.stringify(this.bonos));
                this.bonosUsuario.forEach(bonoUsuario => {
                    const bonoOperadorEncontrado = bonosOperador.find(operador => operador.bonos.some(bono => bono.idBono === bonoUsuario.idBono));
                    if (bonoOperadorEncontrado) {
                        bonoUsuario.status = true;
                    } else {
                        bonoUsuario.status = false;
                    }
                });
            })
            .catch(error => {
                console.log( error);
            })
            .finally(()=>{
              this.loadingMostrarBonos = false;
            })
    },

    cumpleParaBono(item) {
      let conflictos = [];
      
      if(this.infoUsuario) {
        if (this.infoUsuario.idTurno != item.idTurno) {
          conflictos.push("El Turno no es igual al del usuario.")
        }
        if (!this.infoUsuario.areas.includes(item.idArea)) {
          conflictos.push("El Área no conicide con las del usuario.")
        }
        if (this.infoUsuario.idPuesto != item.idCargo) {
          conflictos.push("El Puesto no conicide con las del usuario.")
        }
        if (this.infoUsuario.habilidades.length > item.numHabilidades) {
          conflictos.push("El usuario cuenta con más habilidades.")
        }
        if (this.infoUsuario.habilidades.length < item.numHabilidades) {
          conflictos.push("El usuario cuenta con menos habilidades.")
        }
      }

      return conflictos;
    },
    hide_asignacionBonos() {
      this.dialogBonos = false;
      //this.infoUsuario = JSON.parse(JSON.stringify({}));
    },
    nombrePersona(id) {
      if(!id) return
      return this.personas.find(persona => persona.idPersona == id).nombre
    },

    hide_calculoBonos() {
      this.calculoBonos = [];
      this.usuariosBonos = [];
      this.calculoBonosMasiva = false;
      this.calcularBonosPeriodo = false;
      this.fechaInicioBono = null;
      this.fechaFinBono = null;
    }
    },
  }
</script>

<style>
.readonly-textarea textarea {
  pointer-events: none;
  background-color: #f8f8f8;
}
</style>